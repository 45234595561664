<template>
  <div class="home"></div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { mapActions } from "vuex";
import Api from "@/api/index.js";
import config from "@/common/config";
import storage from "@/common/storage";
const REDIRECT_KEY = "REDIRECT_PATH";

export default {
  name: "login",
  data() {
    return {
      loading: true,
      headers: {}, // 补充头
    };
  },
  mounted() {
    let code = new URL(window.location).searchParams.get("code");
    let state = new URL(window.location).searchParams.get("state");
    sessionStorage.initEnterHref = window.location.href;
    if (code) {
      this.process(code, state);
    } else {
      //||config.env === "uat" 如果需要调试uat环境，则需要添加注释代码到下面判断中
      if (config.env === "development") {
        this.passwordLogin();
      } else {
        this.login();
      }
    }
  },
  methods: {
    ...mapActions(["OnLogin"]),
    loginSuccess(data) {
      this.OnLogin(data);
      const redirect_path = storage.getSessionRemove(REDIRECT_KEY);
      // const { go } = this.$route.query;

      if (redirect_path) {
        location.href = redirect_path;
      } else {
        this.$router.replace({ path: "/home" });
      }
    },
    async process(code, state) {
      const toast = Toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      try {
        const res = await Api.process(
          {
            code,
            state,
          },
          this.headers
        );
        toast.clear();
        if (res.success && res.data) {
          this.loginSuccess(res.data);
        }
      } catch (error) {
        toast.clear();
        Dialog.alert({
          title: "提示",
          message: "当前企业微信未设置工号或设置的工号未开通权限",
          confirmButtonColor: "#5AC47F",
          confirmButtonText: "关闭",
        });
      }
    },

    async passwordLogin() {
      var data = new FormData();
      data.append("grant_type", "password");
      data.append("scope", "userProfile");

      // 管理员账号
      // data.append("username", "superadmin");
      //data.append("password", "123456");
      // 管理员账号 资源管理
      data.append("username", "admin");
      data.append("password", "123456");

      const res = await Api.passwordLogin(data, this.headers);
      this.loginSuccess(res.data);
    },
    async login() {
      let lodingId = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
        loadingType: "spinner",
      });
      const res = await Api.login();
      lodingId.clear();
      if (res.success && res.data) {
        // 授权链接拼接当前链接
        const link = res.data.webAuthorizationLink;
        const href = `${link.substr(
          0,
          link.indexOf("redirect_uri")
        )}redirect_uri=${encodeURIComponent(window.location.href)}${link.substr(
          link.indexOf("&response_type")
        )}`;
        window.location.href = href;
      }
    },
    mounted() {
      const { redirect_path } = this.$route.query;
      if (redirect_path) {
        storage.setSession(REDIRECT_KEY, redirect_path, "1m");
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.home {
  /* background-image: linear-gradient(145deg, #457088, #13193e); */
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .title {
    padding: 30px;
    margin-top: 100px;
    /* color: #fff; */
    .iconfont {
      font-size: 130px;
    }
    flex: 1;
    h1 {
      font-size: 38px;
      margin-top: 30px;
      line-height: 1.5em;
    }
    p {
      padding: 15px 0;
      font-size: 28px;
      color: #999;
    }
  }
  .nav {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    padding-bottom: 50px;
    .item {
      width: 330px;
      border-radius: 8px;
      padding: 20px;
      color: #fff;
      .iconfont {
        font-size: 40px;
      }
      &:first-child {
        background-image: linear-gradient(to right, #2ca0f3, #4790fe);
      }
      &:last-child {
        background-image: linear-gradient(to right, #2dae7a, #48d473);
      }
    }
  }
}
</style>
